<template>
  <router-view/>
</template>
<script>
import ogpImage from '@/assets/images/ogp.png'

export default {
  // metaInfo() {
  //   return {
  //     meta: [
  //       {
  //         vmid: 'viewport',
  //         name: 'viewport',
  //         content: 'width=device-width,initial-scale=1.0',
  //       },
  //     ],
  //   }
  // },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: '',
    // all titles will be injected into this template
    titleTemplate: '%s | QRコード型WebAR作成ツール QRAR',
    meta: [
      { property: 'og:url', content: process.env.VUE_APP_URL },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'QRコード型WebAR作成ツール QRAR' },
      { property: 'og:description', content: 'アプリ不要のQRコードから飛び出すARをカンタンに作成できるサービスです。' },
      { property: 'og:site_name', content: 'QRAR' },
      { property: 'og:image', content: `${process.env.VUE_APP_URL}/img/ogp.png` },
    ],
  },
}
</script>
<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

html {
  font-size: 62.5%; /*ベースを10pxにしています*/
}
body {
  font-size: 1.0rem; /*10px*/
}
</style>
